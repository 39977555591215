import React from "react";
import { useIntl } from "react-intl";
import { PageProps } from "gatsby";

import { Header } from "components/Header";
import { Footer } from "components/Footer";
import withI18n from "i18n/withI18n";
import SEO from "components/seo";

import COLOR from "constants/design";

const NotFoundPage = ({ location }: PageProps) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <SEO
        title={formatMessage({ id: "NOT_FOUND_PAGE_TITLE" })}
        pathname={location.pathname}
        locale
      />
      <div
        className="flex min-h-screen flex-col text-white"
        style={{ backgroundColor: COLOR.DEFAULT_BG_COLOR }}
      >
        <div className="flex-none">
          <Header />
        </div>
        <div className="h-16 flex-none xl:h-32">{/* spacing */}</div>
        <div className="flex min-h-[30vh] flex-1 items-center justify-center">
          <h1 className="text-3xl font-bold">Not Found</h1>
        </div>
        <div className="flex-none">
          <Footer />
        </div>
      </div>
    </>
  );
};

export default withI18n(NotFoundPage);
